import React from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/layout"
import BlogItem from "../components/item-blog"
import Pagination from "../components/pagination"
import { BlogListQuery } from "./__generated__/BlogListQuery"
import Banner from "../components/Carousel"
import Popup from "../components/Popup"

export default function blogList({
    data,
    pageContext,
    location,
}: PageProps<BlogListQuery, {}>) {
    const blogItems = data.allMdx.edges.map(item => (
        <BlogItem data={item.node} key={item.node.id} />
    ))

    return (
        <Layout
            seo={{
                title: "お知らせ",
                description: "当販売店からのお知らせ一覧です",
            }}
            location={location}
        >
            <div className="container mx-auto py-12">
                <div className="title py-12 text-center">
                    <h2 className="font-black text-5xl text-color-4">
                        お知らせ
                    </h2>
                </div>
                {/* <div className="">
                    <Banner />
                </div> */}
                <Popup />
                <div className="grid sm:grid-cols-2 lg:grid-cols-3  justify-center m-5">
                    {blogItems}
                </div>
                <Pagination pageContext={pageContext} type="blog" />
            </div>
        </Layout>
    )
}

export const query = graphql`
    query BlogListQuery($skip: Int!, $limit: Int!) {
        allMdx(
            filter: { fields: { sourceName: { eq: "blog" } } }
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        description
                        date(formatString: "YYYY年MM月DD日")
                        image {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 1920) {
                                    srcSet
                                    ...GatsbyImageSharpFluid
                                }
                                id
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`
