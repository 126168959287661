import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

// import { Calendar } from "react-feather"
import { BlogListQuery_allMdx_edges_node } from "../templates/__generated__/BlogListQuery"
import { IndexPageQuery_blog_edges_node } from "../pages/__generated__/IndexPageQuery"

type ItemBlogProps =
    | BlogListQuery_allMdx_edges_node
    | IndexPageQuery_blog_edges_node
export const ItemBlog: React.FC<{ data: ItemBlogProps }> = ({ data }) => {
    const [focused, changeFocused] = useState(false)

    return (
        <div className="m-4">
            <div className="p-4">
                <Link
                    to={data.fields.slug}
                    title={data.frontmatter.title}
                    onFocus={() => changeFocused(true)}
                    onBlur={() => changeFocused(false)}
                >
                    <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:opacity-70">
                        <img
                            className="w-full object-contain object-center sm:h-80"
                            src={
                                data.frontmatter.image.childImageSharp.fluid.src
                            }
                            alt={data.frontmatter.title}
                        />
                        <div className="p-6 sm:h-40">
                            <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                                {data.frontmatter.date}
                            </h2>
                            <h1 className="title-font text-lg font-medium text-gray-900 mb-3 line-clamp-3">
                                {data.frontmatter.title}
                            </h1>
                            {/* <p className="leading-relaxed mb-3">
                                {data.frontmatter.description}
                            </p> */}
                            <div className="items-center"></div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default ItemBlog
